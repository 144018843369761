.measureBlueBtn {
    background-color: #2E75B6 !important;
}

.measureRedBtn {
    background-color: #B71C1C !important;
}

.measureOrangeBtn {
    background-color: #FB8804 !important;
}

.measureBlueBtn:hover, .measureRedBtn:hover, .measureOrangeBtn:hover {
    color: white
}

.measureNextBtn {
    cursor: pointer;
    color:black;
    background-color: white;
    border-radius: 15px;
    padding: 3px;
}
.measureNextBtn:hover {
    text-decoration: underline;
    color: #dddddd;
}
.disabledMeasureNextBtn {
    color:black;
    background-color: white;
    border-radius: 15px;
    padding: 3px;
}
#accordion .header {
    font-size: 20px;
}

#accordion .header,
#accordion p {
    margin: 0;
}

#accordion-container {
    margin-right: 400px;
}

.dx-theme-material #accordion .dx-accordion-item-title {
    display: flex;
}

.dx-theme-material #accordion .header {
    align-self: center;
}

.options {
    padding: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 340px;
    top: 0;
    background-color: rgba(191, 191, 191, 0.15);
}

.options > .caption {
    font-weight: 500;
    font-size: 18px;
}

.option {
    margin-top: 10px;
}

.option > .caption {
    margin-top: 10px;
    display: inline-block;
}

.option > .dx-tagbox {
    margin-top: 2px;
}

.measure #accordion .dx-accordion-item-title {
    background-color: #f2f2f2 !important;
}

