.switch label input[type=checkbox]:checked + .lever:after {
    background-color: #29bbad;
    left: 1.5rem;
}

.switch label input[type=checkbox]:checked + .lever {
    background-color: #cfe2d6;
}

.emailSettings .title {
    color: black;
    font-weight: bold;
}

.email-settings-table {
    max-width: 500px;
    margin: 0 auto;
}