.form-simple .font-small {
  font-size: 0.8rem; }

.form-simple .header {
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem; }

.form-simple input[type=text]:focus:not([readonly]) {
  border-bottom: 1px solid #3547ff;
  -webkit-box-shadow: 0 1px 0 0 #3547ff;
  box-shadow: 0 1px 0 0 #3547ff; }

.form-simple input[type=text]:focus:not([readonly]) + label {
  color: #4f4f4f; }

.form-simple input[type=password]:focus:not([readonly]) {
  border-bottom: 1px solid #3547ff;
  -webkit-box-shadow: 0 1px 0 0 #3547ff;
  box-shadow: 0 1px 0 0 #3547ff; }

.form-simple input[type=password]:focus:not([readonly]) + label {
  color: #4f4f4f; }

.uiVersion {
  font-size:9px;
  color: #ddd;
  display:block;
  text-align:right;
}
