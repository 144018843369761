.number-input input[type="number"] {
  -webkit-appearance: textfield;
    -moz-appearance: textfield;
          appearance: textfield;
}
.number-input input[type=number]::-webkit-inner-spin-button,
.number-input input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.number-input button {
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  position: relative;
}
.number-input button:active {
  color:#000;
}

.number-input button.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}
.number-input input[type=number] {
  text-align: center;
}
.number-input.number-input {
  border: 1px solid #ced4da;
  width: 8.0rem;
  border-radius: .25rem;
}
.number-input.number-input button {
  width: 1.6rem;
}
.number-input.number-input input[type=number] {
  max-width: 4.5rem;
  padding: .5rem;
  border: 1px solid #ced4da;
  border-width: 0 1px;
  font-size: 1rem;
  height: 2rem;
  color: #495057;
}

.cart-select {
  margin: 0;
  padding: 0 .75rem;
}

.cart-select .caret {
  display: none;
}

.cart-select span.filtrable {
  color: #495057 !important;
}

.cart-select input.select-dropdown {
  color: #495057 !important;
  margin: 0;
  border: 0;
}

.cart-select .dropdown-content {
  top: 40px !important;
}
