.react-datepicker-wrapper
{
  display: block;
}
.custom-file-label::after
{
  Content:'参照'
}

.bill-date {
  width: 200px;
}
