.App{
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.App-logo{
	width: 10rem;
}

.flyout {
	display:flex;
	flex-direction: column;
	min-height:100vh;
	justify-content: space-between;
}

.home-feature-box .fa {
	font-size:6rem;
}

.home-feature-box span {
	display: block;
	color:#111;
	font-weight:bold;
	margin-top:1.5rem;
}

.example-components-list li > a {
  color: #495057;
}

.example-components-list li:last-child > a {
	border-bottom:0;
}

.example-components-list li > a .fa {
	color:rgba(0,0,0,.35);
	float:right;
}
.custom-badge {
	border-radius: 10px;
	padding: 4px 7px;
}

.menuWithNotif i {
	color: #2bbbad !important;
}

.green-square {
	border-radius: 3px;
	background-color: #00C851 !important;
	width: 90px;
	text-align: center;
}

.gray-square {
	border-radius: 3px;
	background-color: #9E9E9E !important;
	width: 90px;
	text-align: center;
}

.gray-background {
	background-color: #848484 !important;
}

.dropdown-item.active, .dropdown-item:active {
	color: #ffffff
}
.moreArea {
	top: 5px;
	width: 145px;
	position: absolute;
	font-size: 14px;
	padding: 7px 0px;
	border-radius: 5px;
	border: 1px solid #E4E4E4;
	box-shadow: 1px 2px 6px -2px gray;
	z-index: 1042;
	background-color: #fff;
	color: #707070;
	right: 10px;
	/*left: 20px;*/
}

.moreArea div {
	padding: 5px 15px;
}

.moreArea div:hover {
	cursor: pointer;
	background-color: #f2f2f2;
	/*color: #fff;*/
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Works for Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.buttonTitle-accordion {
	border-block-color: unset;
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	border-width: unset;
	border-block: none;
	border-block-end-color: none;
	border-block-width: unset;
	width: 100%;
	border: none;

}

.datePicker {
	width: 130px;
}

.mainNav {
	transition-duration: 500ms !important;
	transition-timing-function: ease-in-out !important;
	transition: background 0.5s ease-in-out, margin 0.5s ease-in-out;
}

.hideNav {
	margin-left: -240px;
}

.mainDiv {
	transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.cart-require-any {
	background-color: #707070;
	color: #ffffff;
	border-radius: 2px;
	padding: 0 3px;
	font-size: 11px;
	word-break: keep-all;
	margin-top: 10px;
}

.cart-require {
	background-color: #FF0000;
	color: #ffffff;
	border-radius: 2px;
	padding: 0 3px;
	font-size: 11px;
	word-break: keep-all;
	margin-top: 10px;
}

.dx-state-focused {
	border-color: #ddd !important;
}

#accordion .dx-accordion-item {
	border: 1px solid #ddd !important;
}

.spinner-sm {
	width: 1.2rem;
	height: 1.2rem;
}

.spinner-white {
	color: white !important;
}
