.support-detail {
    background-color: #ffffff;
    width: 100%;
    margin: 50px;
    padding: 20px;
}

@media only screen and (max-width: 600px) {
    .support-detail {
        margin: 0;
    }
}
