.category {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /*gap: 20px;*/
}

.category .item {
    display: flex;
    flex-direction: column;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    align-items: center;
    height: 270px;
    /*margin-bottom: 10px;*/
    /*margin-top: 10px*/
    /*gap: 10px;*/
    flex: 1;
}

.support-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
}
