.input-group.md-form.form-sm.form-1 input {
  border: 1px solid #bdbdbd;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.custom-button-grey {
  background-color: #707070 !important;
}

.custom-button-blue {
  background-color: #64C3F0 !important;
}

.custom-button-red {
  background-color: #D32F2F;
}

.custom-button-lightgreen {
  background-color: #2bbbad !important;
}

.custom-button-green {
  background-color: #00C851 !important;
}

.custom-button-gray {
  background: #848484;
  color: #fff;
}

.custom-button-grey:hover,.custom-button-green:hover, .custom-button-red:hover, .custom-button-lightgreen:hover {
  color: white
}

.custom-text-grey {
  color: #AAAAAA !important;
}

.custom-button-group {
  width: 265px;
}

.custom-button-group-long {
  width: 397px;
}

.custom-button-group button, .custom-button-group-long button {
  width: 120px;
}

.item-footer {
  /*height: 100px;*/
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #00000055;
  text-align: center;
}

.folder-star-header {
  width: 30px;
  margin-top: -7px;
  margin-right: 7px;
}

.folder-star {
  width: 28px;
  margin-top: -7px;
}

.twoRow {
  padding: 0.26em
}

.collapseBtnItem {
  margin: 0;
  font-size: 16px;
  cursor: pointer;
  color:black;
  background-color: white;
  border: none;
  padding: 0.3rem 1rem;
}
.collapseBtnItem:hover {
  font-size: 16px;
  cursor: pointer;
  color: #616161;
  background-color: #F1F1F1;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex: 1;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}

.flex-container-input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .flex-container-input {
    flex-direction: column;
  }
}

.flex-container-remove-button {
  margin-left: 10px;
  height: 38px;
  flex-wrap: nowrap;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .flex-container-remove-button {
    margin-left: 0;
  }
}
.flex-container-remove-button1 {
  margin-left: 57px;
  height: 38px;
  flex-wrap: nowrap;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .flex-container-remove-button1 {
    margin-left: 0;
  }
}
.search-button {
  cursor: pointer;
  background-color: #35547c !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;

  border-width: 0px !important;

}