.sdsBlue {
  width: 13px;
  height: 13px;
  border-radius: 13px;
  background: #4285f4
}

.sdsRed {
  width: 13px;
  height: 13px;
  border-radius: 13px;
  background: #ff3547
}

.sdsYellow {
  width: 13px;
  height: 13px;
  border-radius: 13px;
  background: #ffbb33
}
