div.chip
{
  margin-bottom: 2px;
}

.divideView {
  padding: 2px 5px;
  border: 1px solid #707070;
  border-radius: 3px;
  font-size: 12px;
  /*width: 40px;*/
  text-align: center;
  color: #707070;
  display: inline;
}

.divideNotConfirmed {
  padding: 2px 5px;
  border: 1px solid #FF0000;
  border-radius: 3px;
  font-size: 12px;
  /*width: 40px;*/
  text-align: center;
  color: #ff0000;
  display: inline;
}
