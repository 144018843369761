.orderUrigawa .react-datepicker-wrapper
{
  display: block;
  font-size:.5rem;
  min-width:80px;
}
.orderUrigawa .custom-file-label::after
{
  Content:'参照'
}
.orderUrigawa .form-control
{
  font-size:.5rem;
}
.orderUrigawa textarea.form-control {
  font-size: 0.7rem;
}
.orderUrigawa .btn
{
  font-size:.5rem;
  padding:3px 5px;
}
table.btn-table.orderUrigawa th:nth-child(8),
table.btn-table.orderUrigawa th:nth-child(7),
table.btn-table.orderUrigawa td:nth-child(8),
table.btn-table.orderUrigawa td:nth-child(7)
{
  min-width:200px;
}

.menuButtonView {
  width: 30px;
  height: 30px;
  color: #878787;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuButton {
  width: 30px;
  height: 30px;
  color: #878787;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuButton:hover {
  cursor: pointer;
  background-color: #f2f2f2;
  border-radius: 5px;;
}

.menuArea {
  width: 145px;
  position: absolute;
  font-size: 14px;
  padding: 7px 0px;
  border-radius: 5px;
  border: 1px solid #E4E4E4;
  box-shadow: 1px 2px 6px -2px gray;
  z-index: 1042;
  background-color: #fff;
  color: #707070;
  top: 5px;
  right: 10px;
  /*left: 20px;*/
}

.menuArea div {
  padding: 5px 15px;
}

.menuArea div:hover {
  cursor: pointer;
  background-color: #f2f2f2;
  /*color: #fff;*/
}

.divideTag {
  color: #707070;
  border: 1px solid #707070;
  border-radius: 3px;
  padding: 1px 5px;
  display: inline;
  word-break: keep-all;
}

.redText {
  color: #ff0000
}

.customInput {
  width: 50px;
  text-align: center;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
}

tr.disabledTR {
  background-color: #F2F2F2;
}

.dividedStatus {
  padding: 2px 5px;
  border: 1px solid #707070;
  border-radius: 3px;
  font-size: 12px;
}

.dividedStatusProgress {
  padding: 2px 5px;
  border: 1px solid #FF0000;
  color: #FF0000;
  border-radius: 3px;
  font-size: 12px;
  width: 72px;
}

.grayBtn {
  background-color: #707070
}

.grayBtn:hover {
  color: #ffffff
}

.textArea {
  resize: none;
}

.commentIcon {
  color: #29A3A3;
  font-size: 16px;
}

.commentIconGray {
  color: #BDBDBD;
  font-size: 16px;
}

.customGreenButton {
  background-color: #2bbbad !important;
}

.customGreenButton:hover {
  color: #ffffff;
}

.customOrangeButton {
  background-color: #ff8800 !important;
}

.customOrangeButton:hover {
  color: #ffffff;
}
